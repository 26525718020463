import React, { useEffect } from 'react';
import { Elements as StripeElements } from '@stripe/react-stripe-js';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { useDocument } from '../../../context/document-context';

let stripeReference: Stripe | PromiseLike<Stripe | null> | null = null;

const StripeHandler: React.FC = ({ children }) => {
  const { state } = useDocument();
  useEffect(() => {
    if (state.context.stripePublishableKey) {
      const stripePromise = loadStripe(state.context.stripePublishableKey as string);
      stripeReference = stripePromise;
    }
  }, [state.context.stripePublishableKey]);

  return <StripeElements stripe={stripeReference}>{children}</StripeElements>;
};

export default StripeHandler;
